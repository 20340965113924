import React, { useState, useCallback } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from "axios"

const UploadPage = () => {
  const [files, setFiles] = useState([])
  const [uploading, setUploading] = useState(false)
  const [password, setPassword] = useState("")
  const [progress, setProgress] = useState(0)

  const setImage = e => {
    const inputFiles = e.target.files
    if (inputFiles) {
      setFiles([...inputFiles])
    }
  }

  const uploadPhotos = useCallback(
    async e => {
      e.preventDefault()
      setUploading(true)
      setProgress(null)

      await Promise.all(
        files.map(async file => {
          const { name, type } = file
          const params = {
            Key: name,
            ContentType: type,
            password,
          }

          return axios
            .request({
              method: "post",
              responseType: "json",
              url: "/.netlify/functions/upload-token",
              data: params,
            })
            .then(response => {
              return axios.request({
                method: "PUT",
                url: response.data.signedURL,
                data: file,
                headers: {
                  "x-amz-acl": "public-read",
                },
                onUploadProgress: p => {
                  setProgress(p.loaded / p.total)
                },
              })
            })
            .catch(() => {
              alert("error")
            })
        })
      )

      setUploading(false)
    },
    [files, password]
  )

  return (
    <Layout>
      <SEO title="Upload" />
      <section className="section">
        <div className="columns is-centered">
          <div className="column is-half">
            <form onSubmit={uploadPhotos}>
              <div className="field">
                <div className="file is-boxed is-fullwidth is-centered">
                  <label className="file-label">
                    <input
                      className="file-input"
                      type="file"
                      name="resume"
                      multiple={true}
                      accept="image/*"
                      onChange={setImage}
                    />
                    <span className="file-cta">
                      <span className="file-label">
                        Choose a photo… ({files.length})
                      </span>
                    </span>
                  </label>
                </div>
              </div>
              <div className="control">
                <div className="field">
                  <label className="label">Upload Password</label>
                  <div className="control">
                    <input
                      className="input"
                      type="password"
                      onChange={e => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="field">
                  <button
                    className={
                      +uploading
                        ? "button is-primary is-fullwidth is-loading"
                        : "button is-primary is-fullwidth "
                    }
                    type="submit"
                  >
                    Upload photo
                  </button>
                </div>
                {uploading && (
                  <div className="field">
                    <progress
                      className="progress is-danger"
                      value={progress}
                      max="1"
                    >
                      {progress}
                    </progress>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default UploadPage
